<template>
    <wit-modal
        v-model="showModal"
        title="Upload file"
        variant="primary"
        disableDBlock
        size="sm"
        customClass="wit-custom-query-storage-input-modal"
        @input="emitInput"
    >
        <h2>
            Do you want to upload this file?
        </h2>
        <p v-for="file in files">{{ file.name }}</p>
        <div class="wit-custom-query-storage-input-modal__btn-row-wrapper">
            <div class="wit-custom-query-storage-input-modal__btn-row">
                <b-button variant="warning" size="sm" @click="yes">Yes</b-button>
                <b-button variant="secondary" size="sm" @click="close">No</b-button>
            </div>
        </div>
    </wit-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        files: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        WitModal: () => import('@/components/Modals/WitModal.vue'),
    },
    data() {
        return {
            showModal: false,
        }
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    created() {
        this.showModal = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.showModal)
        },
        yes() {
            this.$emit('upload')
        },
        close() {
            this.showModal = false
            this.emitInput()
        },
    },
}
</script>

<style lang="scss">
.wit-custom-query-storage-input-modal {
    h2 {
        font-size: 1.2rem;
        text-align: center;
    }

    > p {
        text-align: center;
    }

    .wit-custom-query-storage-input-modal__btn-row-wrapper {
        .wit-custom-query-storage-input-modal__btn-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;

            button {
                margin: 0 5px;
            }
        }
    }
}
</style>
